import React, { Component } from 'react';

class Title extends Component {
  render() {
    return (
      <div className='title-wrapper'>
        <a className='title-top' href='/'>statusW</a>
      </div>
    )
  }
}

export default Title;